<template>
  <div class="mente-wrapper">
    <i class="el-icon-warning icon-style" />
    <h1 class="title-style">
      ただいまメンテナンス中のため、しばらくの間ご利用いただけません。
    </h1>
  </div>
</template>

<script>
import { ActionTypes } from '@/store/actions';
export default {
  async created() {
    await this.$store.dispatch(ActionTypes.healthCheck);
    if (this.$store.state.isAlive) {
      this.$router.push('/');
    }
  },
};
</script>

<style lang="scss" scoped>
.mente-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  height: 100vh;
  .icon-style {
    font-size: 2rem;
    margin-right: 1rem;
  }
  .title-style {
    size: 2rem;
    margin: 0;
  }
}
</style>
